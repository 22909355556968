import axios from 'axios';

// Base URL
export const baseURL = 'http://127.0.0.1:8000';

// Endpoints
export const getAllSongs = '/allsongs';
export const deleteLock = '/deleteLock';
export const generateSong = '/generateSong';
export const getSong = youtubeId => `/song/${youtubeId}`;
export const getFile = (youtubeId, fileType) => `/file/${youtubeId}/${fileType}`;
export const openSongs = '/openSongs';

// Axios instance
const apiClient = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Methods for API calls
export default {
  // Get all songs
  fetchAllSongs() {
    return apiClient.get(getAllSongs);
  },
  
  // Delete lock
  deleteLock() {
    return apiClient.get(deleteLock);
  },

  // Generate a song (POST)
  generateSong(youtubeLink) {
    return apiClient.post(generateSong, { youtubeLink: youtubeLink });
  },

  // Get song by youtubeId
  fetchSong(youtubeId) {
    return apiClient.get(getSong(youtubeId));
  },

  // Get file by youtubeId and file type
  fetchFile(youtubeId, fileType) {
    return apiClient.get(getFile(youtubeId, fileType));
  },
  
  // Get file by youtubeId and file type
  openSongs() {
    return apiClient.get(openSongs);
  }
};