<template>
  <div id="app">
    <YoutubeVideo :songId="this.$route.params.songId" @stateChange="handlePlayerStateChange" />
    <AudioComponent :songId="this.$route.params.songId" />
    <SubtitleComponent :songId="this.$route.params.songId" />
    <AudioComparison :songId="this.$route.params.songId" />
  </div>
</template>

<script>
import YoutubeVideo from './Song/YoutubeVideo.vue';
import AudioComponent from './Song/AudioComponent.vue';
import SubtitleComponent from './Song/SubtitleComponent.vue';
import AudioComparison from './Song/AudioComparison.vue';
import eventBus from '../assets/eventBus';

export default {
  props: {
    songId: {
      type: String,
      required: true,
    },
  },

  components: {
    YoutubeVideo,
    AudioComponent,
    SubtitleComponent, 
    AudioComparison
  },
  methods: {
    handlePlayerStateChange(state) {
      console.log("Player state changed:", state); // Debugging Log
      // Event senden an den AudioComponent
      eventBus.emit('syncAudio', state);
    },
    onMounted() {
      console.log('mounted ', this.$route.params.songId)
    }
  }
};
</script>

<style>
#app {
  text-align: center;
  margin-top: 20px;
}
</style>