<template>
  <div class="audio-component">

    <!-- Audio Elemente -->
    <audio ref="audio1" :src="'http://localhost:8000/file/' + songId + '/vocals'" preload="true"></audio>
    <audio ref="audio2" :src="'http://localhost:8000/file/' + songId + '/no_vocals'" preload="true"></audio>

    <!-- Lautstärkeregler -->
    <div class="controls">
      <label>
        Volume:
        <input type="range" min="0" max="1" step="0.05" v-model="totalVolume" @input="updateVolume" />
      </label>
      <label>
        Balance (Vocals zu Instrumentals):
        <input type="range" min="0" max="1" step="0.05" v-model="balance" @input="updateBalance" />
      </label>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import eventBus from '../../assets/eventBus';

export default {
  props: {
    songId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const audio1 = ref(null);
    const audio2 = ref(null);
    const totalVolume = ref(1); // Gesamtlautstärke
    const balance = ref(0.2); // Initial Balance für gleiche Lautstärke

    function updateVolume() {
      // Setzt die Gesamtlautstärke für beide Audiospuren
      audio1.value.volume = totalVolume.value * balance.value;
      audio2.value.volume = totalVolume.value;
    }

    function updateBalance() {
      // Setzt die relative Lautstärke zwischen audio1 und audio2
      audio1.value.volume = totalVolume.value * balance.value;
      audio2.value.volume = totalVolume.value;
    }

    function syncAudio(stateObject) {
      console.log(stateObject)

      const { currentTime, state: playState } = stateObject;

      if (playState === 1 || playState === 3) { // Playing
        audio1.value.currentTime = currentTime;
        audio2.value.currentTime = currentTime;

        audio1.value.play();
        audio2.value.play();
        
      } else if (playState === 2) { // Paused
        audio1.value.pause();
        audio2.value.pause();
      }
    }

    onMounted(() => {
      eventBus.on('syncAudio', syncAudio);
      updateVolume(); // Initiale Lautstärke setzen
    });

    onUnmounted(() => {
      eventBus.off('syncAudio', syncAudio);
    });

    // Beobachte Änderungen an totalVolume und balance, um die Lautstärke live anzupassen
    watch([totalVolume, balance], updateVolume);

    return {
      audio1,
      audio2,
      totalVolume,
      balance,
      updateVolume,
      updateBalance,
    };
  },
};
</script>

<style scoped>
.audio-component {
  text-align: center;
  margin-top: 20px;
}

.controls {
  margin-top: 10px;
}

label {
  display: block;
  margin-bottom: 8px;
}
</style>