<template>
    <div class="subtitle-container">
      <div class="subtitle-line">
        <span
          v-for="(word, index) in displayedWords"
          :key="index"
          class="subtitle-word"
          :class="{ visible: word.visible }"
          :style="{
            left: word.position.x + 'px',
            top: word.position.y + 'px'
          }"
        >
          {{ word.text + "  "}}
        </span>
      </div>
    </div>
  </template>
  
  
  <script setup>
import { ref, onMounted, onUnmounted, watch, nextTick, defineProps } from 'vue';
import eventBus from '../../assets/eventBus'; // Importiere eventBus

const props = defineProps(['songId']);
const displayedWords = ref([]); // Wörter, die im Container angezeigt werden
const currentTime = ref(0); // Lokale Zeit der Komponente
const isPlaying = ref(false); // Spielstatus
let timer = null; // Timer-Variable

// Lädt und parst die Untertitel
async function loadSubtitles() {
  try {
    const response = await fetch('http://localhost:8000/file/' + props.songId + '/srt');
    const srtText = await response.text();
    parseSubtitles(srtText); // Direktes Parsen ohne Rückgabe
    await nextTick(); // Warten bis DOM aktualisiert ist
  } catch (error) {
    console.error("Fehler beim Laden der Untertiteldatei:", error);
  }
}

// Funktion zum Parsen des .srt-Dateiformats und zur Generierung von Wortobjekten
const parseSubtitles = (data) => {
  const subtitles = data.split(/\r?\n\r?\n/).filter(Boolean).map(str => {
    let [idLine, timeLine, ...textLines] = str.split(/\r?\n/);
    const [startTime, endTime] = timeLine.split(' --> ').map(timeToFloat);
    const words = textLines.join(' ').split(' ').map((word) => ({
      text: word,
      startTime,
      endTime,
      visible: false,
      position: { x: 0, y: 0 } // Initialposition für Wörter
    }));
    return { id: parseInt(idLine), words };
  });
  generateWords(subtitles);
};

// Hilfsfunktion zur Umwandlung von Zeit in Sekunden
const timeToFloat = (timeString) => {
  const [hour, minute, seconds] = timeString.split(':').map(x => parseFloat(x.replace(',', '.')));
  return hour * 3600 + minute * 60 + seconds;
};

// Funktion zur Generierung der Wortpositionen
const generateWords = (subtitles) => {
  const words = subtitles.flatMap(subtitle => subtitle.words);
  const containerWidth = window.innerWidth; // Breite des Containers
  const containerHeight = 200; // Feste Höhe für bessere Sichtbarkeit

  let totalWidth = 0;
  const wordMargin = 10; // Abstand zwischen den Wörtern
  const lineHeight = 30; // Höhe der Zeilen
  let lastY = 0; // Letzte Y-Position

  displayedWords.value = []; // Leere vorherige Wörter

  words.forEach(word => {
    const span = document.createElement('span');
    span.innerText = word.text;
    span.style.fontSize = '50px'
    document.body.appendChild(span); // Temporär zum Berechnen der Breite

    const wordWidth = span.offsetWidth;

    // Überprüfen, ob das Wort in die aktuelle Zeile passt
    if (totalWidth + wordWidth > (containerWidth - 200)) {
      totalWidth = 0; // Zurücksetzen der Breite für die neue Zeile
    }

    // Setze die Position für das Wort
    const randomYOffset = (Math.random() - 0.5) * 20; // Zufällige Abweichung in der Y-Achse
    word.position = { x: totalWidth, y: Math.min(lastY + randomYOffset, containerHeight - lineHeight) };

    displayedWords.value.push(word);

    // Aktualisiere die Gesamtbreite für das nächste Wort
    totalWidth += wordWidth + wordMargin; // Aktualisiere die Breite

    document.body.removeChild(span); // Entferne das temporäre Element
  });
};

// Timer zum kontinuierlichen Hochzählen der Zeit
const startTimer = () => {
  timer = setInterval(() => {
    if (isPlaying.value) {
      currentTime.value += 0.6; // Erhöhe die Zeit alle 100ms
    }
  }, 600);
};

const stopTimer = () => {
  if (timer) {
    clearInterval(timer);
    timer = null;
  }
};

// Verarbeitet die Bus-Ereignisse und aktualisiert `currentTime`
const updateAudioStatus = ({ state, currentTime: newTime }) => {
  isPlaying.value = state === 1;
  currentTime.value = newTime;

  if (isPlaying.value) {
    startTimer();
  } else {
    stopTimer();
  }
};

// Aktiviert die Sichtbarkeit der Wörter basierend auf der aktuellen Zeit
watch(currentTime, (newTime) => {
  displayedWords.value.forEach(word => {
    word.visible = newTime >= word.startTime - 1.2 && newTime <= word.endTime + 0.1;
  });
});

onMounted(async () => {
  await loadSubtitles();
  eventBus.on('syncAudio', updateAudioStatus);
  startTimer();
});

onUnmounted(() => {
  eventBus.off('syncAudio', updateAudioStatus);
  stopTimer();
});
</script>

  
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@keyframes slowToFastOpacity {
    0% {
        opacity: 0;
    }
    95% {
        opacity: 0.95; /* Fast vollständige Transparenz in den ersten 95% */
    }
    100% {
        opacity: 1; /* Schneller Übergang zu voller Deckkraft in den letzten 5% */
    }
}

.subtitle-container {
  position: absolute;
  text-align: center;
  width: 100vw; /* Maximal die Breite des Bildschirms */
  height: 200px; /* Maximal die Breite des Bildschirms */
}

.subtitle-line {
  position: relative; /* Ermöglicht die absolute Positionierung der Wörter innerhalb der Zeile */
  font-size: 50px;
  height: 200px; /* Feste Höhe für bessere Sichtbarkeit */
  overflow: hidden; /* Versteckt Überlauf */
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 0.04em;
  -webkit-text-fill-color: orange;

  font-family: "Roboto", sans-serif; /* Roboto regular */
  font-weight: 400;
  font-style: normal;
}

.subtitle-word {
  position: absolute; /* Ermöglicht Überlappung */
  white-space: nowrap; /* Verhindert Zeilenumbruch */
  opacity: 0;
  transition: opacity 1s ease-in;
}

.subtitle-word.visible {
  opacity: 1;
}
</style>

  