<template>
  <div class="audio-comparison">
    <!-- Hidden audio elements for controlling playback -->

    <!-- Display similarity score -->
    <div class="score">Punkte: <span>{{ state.accumulatedScore.toFixed(2) }}</span></div>

    <!-- Canvas for frequency bin visualizations -->
    <div class="canvas-container">
      <div>
        <div>Vocals</div>
        <canvas ref="vocalsCanvas" width="300" height="100"></canvas>
      </div>
      <div>
        <div>Microphone</div>
        <canvas ref="micCanvas" width="300" height="100"></canvas>
      </div>
    </div>
    <audio ref="vocalsAudio" :src="'http://localhost:8000/file/' + songId + '/vocals'" preload="true"></audio>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, reactive } from 'vue';
import eventBus from '../../assets/eventBus';

export default {
  props: {
    songId: {
      type: Object,
      required: true
    }
  },
  setup() {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const micCtx = new (window.AudioContext || window.webkitAudioContext)();
    const analyserHTMLAudio = audioCtx.createAnalyser();
    const analyserMicAudio = micCtx.createAnalyser();
    const vocalsAudio = ref(null);
    const vocalsCanvas = ref(null);
    const micCanvas = ref(null);

    const state = reactive({
      similarityScore: 0,
      accumulatedScore: 0,
      isComparing: false
    });

    const initMicrophone = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const micSource = micCtx.createMediaStreamSource(stream);
        micSource.connect(analyserMicAudio);
      } catch (error) {
        console.error("Microphone access denied:", error);
      }
    };

    const compareAudio = () => {
      if (!state.isComparing) return;

      const bufferLength = analyserHTMLAudio.frequencyBinCount;
      const dataHTMLAudio = new Uint8Array(bufferLength);
      const dataMicAudio = new Uint8Array(bufferLength);

      analyserHTMLAudio.getByteFrequencyData(dataHTMLAudio);
      analyserMicAudio.getByteFrequencyData(dataMicAudio);

      let totalScore = 0;
      for (let i = 0; i < bufferLength; i++) {
        const normalizedHTMLAudio = dataHTMLAudio[i] / 255;
        const normalizedMicAudio = dataMicAudio[i] / 255;
        const difference = Math.abs(normalizedHTMLAudio - normalizedMicAudio);
        const score = dataHTMLAudio[i] > 20 && dataMicAudio[i] > 20 ? Math.max(0, (1 - difference) ** 2 * 100) : 0;
        totalScore += score;
      }

      state.similarityScore = Math.min(100, totalScore / bufferLength);
      state.accumulatedScore += state.similarityScore > 30 ? 0.01 : 0;

      requestAnimationFrame(compareAudio);
    };

    const visualizeMicrophone = () => {
      const bufferLength = analyserMicAudio.frequencyBinCount;
      const dataMicAudio = new Uint8Array(bufferLength);
      analyserMicAudio.getByteFrequencyData(dataMicAudio);

      const micCanvasCtx = micCanvas.value.getContext("2d");
      micCanvasCtx.clearRect(0, 0, micCanvas.value.width, micCanvas.value.height);

      for (let i = 0; i < bufferLength; i++) {
        const barHeight = dataMicAudio[i] / 255 * micCanvas.value.height;
        micCanvasCtx.fillStyle = 'rgba(204, 51, 51, 0.6)';
        micCanvasCtx.fillRect(i * 2, micCanvas.value.height - barHeight, 1, barHeight);
      }

      requestAnimationFrame(visualizeVocals);
    };

    const visualizeVocals = () => {
      const bufferLength = analyserHTMLAudio.frequencyBinCount;
      const dataHTMLAudio = new Uint8Array(bufferLength);
      analyserHTMLAudio.getByteFrequencyData(dataHTMLAudio);

      const vocalsCanvasCtx = vocalsCanvas.value.getContext("2d");
      vocalsCanvasCtx.clearRect(0, 0, vocalsCanvas.value.width, vocalsCanvas.value.height);

      for (let i = 0; i < bufferLength; i++) {
        const barHeight = dataHTMLAudio[i] / 255 * vocalsCanvas.value.height;
        vocalsCanvasCtx.fillStyle = 'rgba(0, 102, 204, 0.6)';
        vocalsCanvasCtx.fillRect(i * 2, vocalsCanvas.value.height - barHeight, 1, barHeight);
      }

      requestAnimationFrame(visualizeMicrophone);
    };

    const syncAudioHandler = (stateObject) => {
      const { currentTime, state: playState } = stateObject;
      vocalsAudio.value.currentTime = currentTime;

      if (playState === 1) {
        syncPlay();
      } else if (playState === 3) {
        state.accumulatedScore = 0;
      } else if (playState === 2) {
        syncPause();
      }
    };

    const syncPlay = () => {
      vocalsAudio.value.play();
      state.isComparing = true;
      compareAudio();
    };

    const syncPause = () => {
      vocalsAudio.value.pause();
      state.isComparing = false;
    };

    onMounted(() => {
      analyserHTMLAudio.smoothingTimeConstant = 0.9;
      analyserMicAudio.smoothingTimeConstant = 0.9;
      analyserHTMLAudio.fftSize = 256;
      analyserMicAudio.fftSize = 256;

      initMicrophone();

      vocalsAudio.value.crossOrigin = 'anonymous';
      const vocalSource = audioCtx.createMediaElementSource(vocalsAudio.value);
      vocalSource.connect(analyserHTMLAudio);

      eventBus.on('syncAudio', syncAudioHandler);

      visualizeVocals();
    });

    onUnmounted(() => {
      eventBus.off('syncAudio', syncAudioHandler);
    });

    return {
      vocalsAudio,
      vocalsCanvas,
      micCanvas,
      state
    };
  }
};
</script>

<style scoped>
.audio-comparison {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}
.score {
  font-size: 24px;
  margin-top: 20px;
}
.canvas-container {
  display: flex;
  margin-top: 20px;
}
.canvas-container canvas {
  border: 1px solid #ccc;
  margin: 10px;
}
</style>
