<template>
    <div class="container">
      <div class="position-relative">
        <div
          v-for="(song, index) in songs"
          :key="song"
          class="card position-absolute"
          :style="getCardStyle(index)"
        >
        <div v-if="songs">
           <div class="loader"></div>
        </div>
            
          <div class="card-body">
            <h5 class="card-text">{{ song }}</h5> <!-- Adjust according to your data structure -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import api from '../assets/api';
  import { onUpdated } from 'vue';

  export default {
    data() {
      return {
        currentlyGenerating: false,
        songs: [],
      };
    },
    props: {
      updateProp: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      async fetchUntilNoOpenSongs() {
        return await new Promise(() => {
          const interval = setInterval(async () => {
            const response = await api.openSongs();
            this.songs = response.data;
            if (this.songs.length == 0) clearInterval(interval);
          }, 2500);
        });
      },

      async fetchSongs() {
        try {
          const response = await api.openSongs();
          this.songs = response.data;

          await this.fetchUntilNoOpenSongs();
          this.currentlyGenerating = false;
          this.$emit('updated', false);
        } catch (error) {
          console.error('Error fetching songs:', error);
        }
      },
      getCardStyle(index) {
        return {
          top: `${100 + index * 130}px`, // Adjust the positioning as needed
          left: '10%', // Set desired left position
          height: '150px',
          width: '240px',
          margin: '10px',
          borderRadius: '10%',
          transform: 'translateX(100%)',
          zIndex: 1 - index, // Layering cards
        };
      },
    },
    mounted() {
      this.fetchSongs(); // Fetch initially only
      this.currentlyGenerating = false; // Flag to prevent re-fetch on every update

      onUpdated(() => {
        if (!this.currentlyGenerating) {
          this.currentlyGenerating = true;
          this.fetchSongs(); // Fetch initially only
        }
      });
    },
  };
  </script>
  
  <style scoped>
  .card {
    width: 18rem; /* Set the width of the cards */
  }
  /* HTML: <div class="loader"></div> */
.loader {
  width: 30px;
  margin: 0px, 5px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #000;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}
  </style>