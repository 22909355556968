<script setup>
import { RouterLink } from 'vue-router'
</script>
 
<template>
      <div class="container">
        <div class="col-12 col-sm-6 col-lg-6">
          <div class="form-group">
            <label for="search">Search</label>
            <input type="text" class="form-control" v-model="searchText" placeholder="Songname ...">
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-6">
          <form @submit.prevent="generateSong">
            <div class="form-group">
              <label for="text">New Song (YouTube Link)</label>
              <input type="text" class="form-control" v-model="newSongLink" placeholder="https://www.youtube.com/watch?v=... or https://youtu.be/...">
            </div>
            <button type="submit" class="btn btn-primary">Generate</button>
            <OpenSongs :updateProp="updateSongQueue" @updated="onUpdate" />
          </form>
        </div>
      </div>
  
      <div class="container">
        <div class="row">
          <div id="songs">
            <div v-for="song in filteredSongs" :key="song.id" class="song">
              <router-link :to="{ name: 'SongView', params: { songId: song.id } }">
                <div class="image-parent">
                  <img :src="song.thumbnail" class="thumbnail" :alt="song.title">
                  {{ song.title }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
  </template>
  
  <script>
  import OpenSongs from './OpenSongs.vue';
  import api from '../assets/api';

  export default {
    components: { OpenSongs },
    props: {
        msg: String
    },
    data() {
      return {
        searchText: '',
        newSongLink: '',
        updateSongQueue: false,
        songs: []
      };
    },
    computed: {
      filteredSongs() {
        return this.songs.filter(song =>
          song.title.toLowerCase().includes(this.searchText.toLowerCase())
        );
      }
    },
    methods: {
      async fetchSongs() {
        try {
          const response = await api.fetchAllSongs();
          const data = response.data;
          this.songs = data;
        } catch (error) {
          console.error('Error fetching songs:', error);
        }
      },
      async generateSong() {
        // Handle song generation logic here
        try {
          api.generateSong(this.newSongLink)

          this.updateSongQueue = true;

        } catch (error) {
          console.error('Error fetching songs:', error);
        }
      },
      onUpdate(newValue) {
        this.updateSongQueue = false;
        console.log('Child component has updated with value:', newValue);
        // Callback logic after the child has updated
      }
    },
    mounted() {
      this.fetchSongs();  // Fetch songs when the component is mounted
    }
  }
  </script>
  
  <style scoped>
    #songs {
      display: flex;
      flex-direction: column;
      justify-content: baseline;
      text-align: left;
    }
    .container {
    display: flex; /* or inline-flex */
    justify-content: center;
    flex-wrap: wrap;
}

.thumbnail {
    height: 135px;
    width: 240px;
    margin: 10px;
    border-radius: 10%;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.word {
    position: absolute;
    margin: 10px;

    
    text-align: center;
    color: white;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;

    display: inline-block;
    width: fit-content;
}

.currentWord {
    color: green;
    font-size: 40px;
    letter-spacing: 2px;
}

input[type=range][orient=vertical]
{
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* Chromium */
    width: 8px;
    height: 175px;
    padding: 0 5px;
}

#volumeControl {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}

.animatedWord {
    position: absolute;
    top: 25px;
    left: -50%;
    font-size: 5em;
    color: #fff;
}

.green {
    color: green;
}

.animationOverWord {
    /* This will create the layer
       over original text*/
    content: attr(data-text);
    position: absolute;
    top: 25px;
    left: -50%;

    /* Setting different color than
       that of original text  */
    color: green;
    overflow: hidden;

    /* Setting width as 0*/
    width: 0%;
    transition-duration: var(--animateTime);
}

.animationOverWord:hover {

    /* Setting width to 100 on hover*/
    width: 100%;
}

.wrapper {
    background-color: #fff;
}

.bigWord {
    
    display: inline-block;
    width: fit-content;

    color: #fff;
    /*text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;*/

    font-size: 500px;
    font-weight: 900;
    
	text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;

    margin-left: 10px;
}

.wrapper span {
    font-size: 90px;
    font-weight: 900;
    text-align: center;
    cursor: pointer;
    -webkit-text-stroke-color:black;
    -webkit-text-stroke-width:0.02em;
    -webkit-text-fill-color:transparent;
    -webkit-background-clip:text;
    background-repeat: no-repeat;
    -webkit-transition : background-size var(--animateTime)
    cubic-bezier(0.67,0.01,0.15,0.98);
    
    transition: background-size var(--animateTime)
    cubic-bezier(0.67,0.01, 0.15,0.98);
}

.box {
    background-image:
    linear-gradient(135deg, green 0%, green 50%,
    transparent 50.1%);
    -webkit-background-size: 0 100%;
    background-size: 0 100%;
}

.content:hover .box{
    -webkit-background-size: 230% 100%;
    background-size: 230% 100%;
}


.sizinger {
    -webkit-background-size: 230% 100%;
    background-size: 230% 100%;
}

.notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

:root {
    --divWidth: 0px;
    --time: 10s;
}

.wordWrapper {
    position: relative;
    overflow: hidden;
}
  
#words {
    white-space: nowrap;
}

.runningAnimation {
    animation: move var(--time) linear 0s infinite;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    animation-play-state: paused;
}


@keyframes move {
    0% {
        transform: translate(0,0);
    }
    100% {
        transform: translate(var(--divWidth),0);
    }
}

#bar {
    position: fixed;
    top: 70%;
    left: 1%;
    width: 40%;
    height: 40%;
    margin-left: -200px;
    -webkit-transition: 0.1s ease all;

    -webkit-transform: rotate(90deg) scaleY(-1); /* Safari and Chrome */
    -moz-transform: rotate(90deg) scaleY(-1);   /* Firefox */
    -ms-transform: rotate(90deg) scaleY(-1);   /* IE 9 */
    -o-transform: rotate(90deg) scaleY(-1);   /* Opera */
    transform: rotate(90deg) scaleY(-1);
}

#bar2 {
    position: fixed;
    top: 70%;
    right: 1%;
    width: 40%;
    height: 40%;
    margin-right: -200px;
    -webkit-transition: 0.1s ease all;


    -webkit-transform: rotate(-90deg) scaleX(-1) scaleY(-1); /* Safari and Chrome */
    -moz-transform: rotate(-90deg) scaleX(-1) scaleY(-1);   /* Firefox */
    -ms-transform: rotate(-90deg) scaleX(-1) scaleY(-1);   /* IE 9 */
    -o-transform: rotate(-90deg) scaleX(-1) scaleY(-1);   /* Opera */
    transform: rotate(-90deg) scaleX(-1) scaleY(-1);
}

.p {
    background-color: #55aa00;
    height: 100%;
    width: 5%;
    float: left;
}

#P102 {
    background: linear-gradient(
        90deg,
        rgba(255, 0, 0, 1) 0%,
        rgba(255, 154, 0, 1) 100%
    );
}

#P202 {
    background: linear-gradient(
        90deg,
        rgba(255, 154, 0, 1) 0%,
        rgba(208, 222, 33, 1) 100%
    );
}

#P302 {
    background: linear-gradient(
        90deg,
        rgba(208, 222, 33, 1) 0%,
        rgba(79, 220, 74, 1) 100%
    );
}

#P402 {
    background: linear-gradient(
        90deg,
        rgba(79, 220, 74, 1) 0%,
        rgba(63, 218, 216, 1) 100%
    );
}

#P502 {
    background: linear-gradient(
        90deg,
        rgba(63, 218, 216, 1) 0%,
        rgba(47, 201, 226, 1) 100%
    );
}

#P602 {
    background: linear-gradient(
        90deg,
        rgba(47, 201, 226, 1) 0%,
        rgba(28, 127, 238, 1) 100%
    );
}

#P702 {
    background: linear-gradient(
        90deg,
        rgba(28, 127, 238, 1) 0%,
        rgba(95, 21, 242, 1) 100%
    );
}

#P802 {
    background: linear-gradient(
        90deg,
        rgba(95, 21, 242, 1) 0%,
        rgba(186, 12, 248, 1) 100%
    );
}

#P902 {
    background: linear-gradient(
        90deg,
        rgba(186, 12, 248, 1) 0%,
        rgba(251, 7, 217, 1) 100%
    );
}

#P10 {
    background: linear-gradient(
        90deg,
        rgba(255, 0, 0, 1) 0%,
        rgba(255, 154, 0, 1) 100%
    );
    height: 0%;
}

#P20 {
    background: linear-gradient(
        90deg,
        rgba(255, 154, 0, 1) 0%,
        rgba(208, 222, 33, 1) 100%
    );
    height: 0%;
}

#P30 {
    background: linear-gradient(
        90deg,
        rgba(208, 222, 33, 1) 0%,
        rgba(79, 220, 74, 1) 100%
    );
    height: 0%;
}

#P40 {
    background: linear-gradient(
        90deg,
        rgba(79, 220, 74, 1) 0%,
        rgba(63, 218, 216, 1) 100%
    );
    height: 0%;
}

#P50 {
    background: linear-gradient(
        90deg,
        rgba(63, 218, 216, 1) 0%,
        rgba(47, 201, 226, 1) 100%
    );
    height: 0%;
}

#P60 {
    background: linear-gradient(
        90deg,
        rgba(47, 201, 226, 1) 0%,
        rgba(28, 127, 238, 1) 100%
    );
    height: 0%;
}

#P70 {
    background: linear-gradient(
        90deg,
        rgba(28, 127, 238, 1) 0%,
        rgba(95, 21, 242, 1) 100%
    );
    height: 0%;
}

#P80 {
    background: linear-gradient(
        90deg,
        rgba(95, 21, 242, 1) 0%,
        rgba(186, 12, 248, 1) 100%
    );
    height: 0%;
}

#P90 {
    background: linear-gradient(
        90deg,
        rgba(186, 12, 248, 1) 0%,
        rgba(251, 7, 217, 1) 100%
    );
    height: 0%;
}
  </style>
  