import { createRouter, createWebHistory } from 'vue-router'
import FrontPage from '../components/FrontPage.vue'
import SongView from '../components/SongView.vue'

const routes = [
  {
    path: '/',
    name: 'FrontPage',
    component: FrontPage
  },
  {
    path: '/song/:songId',
    name: 'SongView',
    component: SongView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router