<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <router-link :to="{ name: 'FrontPage' }"> 🎙️ Any Karaoke 🎤 </router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item active">
                    <router-link :to="{ name: 'FrontPage' }">Home</router-link>
                </li>
            </ul>
        </div>
    </nav>
    <router-view />
  </template>
  
  <script setup>
  </script>

  <style scoped>
  </style>