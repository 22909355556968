<template>
  <div>
    <div :id="elementId" style="width: 900px; height: 506px;"></div>
  </div>
</template>

<script>
import { onMounted, ref, defineComponent } from 'vue';

export default defineComponent({
  name: 'YoutubeVideo',
  props: {
    songId: {
      type: String,
      required: true,
    },
  },
  emits: ['stateChange'],
  setup(props, { emit }) {
    console.log(props.songId);
    const player = ref(null);
    const elementId = `youtube-player-${Math.random().toString(36).substr(2, 9)}`;

    const onPlayerStateChange = (event) => {
      const currentTime = player.value ? player.value.getCurrentTime() : 0;
      emit('stateChange', {
        state: event.data,
        currentTime: currentTime,
      });
    };
    function onPlayerReady(){
      player.value.mute();
    }

    onMounted(() => {
      window.YT.ready(() => {
        player.value = new window.YT.Player(elementId, {
          videoId: props.songId,
          width: '900',
          height: '506',
          events: {
            onStateChange: onPlayerStateChange,
            onReady: onPlayerReady,
          },
        });
      });
    });

    return {
      elementId,
    };
  },
});
</script>

<style scoped>
</style>
